[id*='-donation-overlay'] .donation-payment input[type=text] {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
/* Button styles */
/* You can make modifications below to change the look of the buttons*/
a.donate-btn:active,
a.donate-btn:focus,
a.donate-btn:hover,
a.donate-btn {
  text-transform: capitalize;
  text-decoration: none;
  color: #fff;
  background-color: #428BCA;
  border-color: #357EBD;
  display: inline-block;
  margin-bottom: 4px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  -webkit-user-select: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
/* CoinTipper styles */
/* The code below is required to generate the modal */
/* Only edit if you know what you're doing */
[id*='-donation-overlay'] .donation-payment input[type=text] {
  width: 100%;
  box-sizing: border-box;
  cursor: default;
}
[id*='-donation-overlay'] .donation-payment p {
  font-size: 14px;
}
[id*='-donation-overlay'] .donation-payment code {
  margin-top: 4px;
  display: block;
}
[id*='-donation-overlay'] article,
[id*='-donation-overlay'] aside,
[id*='-donation-overlay'] details,
[id*='-donation-overlay'] figcaption,
[id*='-donation-overlay'] figure,
[id*='-donation-overlay'] footer,
[id*='-donation-overlay'] header,
[id*='-donation-overlay'] hgroup,
[id*='-donation-overlay'] main,
[id*='-donation-overlay'] nav,
[id*='-donation-overlay'] section,
[id*='-donation-overlay'] summary {
  display: block;
}
[id*='-donation-overlay'] audio,
[id*='-donation-overlay'] canvas,
[id*='-donation-overlay'] progress,
[id*='-donation-overlay'] video {
  display: inline-block;
  vertical-align: baseline;
}
[id*='-donation-overlay'] audio:not([controls]) {
  display: none;
  height: 0;
}
[id*='-donation-overlay'] [hidden],
[id*='-donation-overlay'] template {
  display: none;
}
[id*='-donation-overlay'] a {
  background: transparent;
}
[id*='-donation-overlay'] a:active,
[id*='-donation-overlay'] a:hover {
  outline: 0;
}
[id*='-donation-overlay'] abbr[title] {
  border-bottom: 1px dotted;
}
[id*='-donation-overlay'] b,
[id*='-donation-overlay'] strong {
  font-weight: bold;
}
[id*='-donation-overlay'] dfn {
  font-style: italic;
}
[id*='-donation-overlay'] h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
[id*='-donation-overlay'] mark {
  background: #ff0;
  color: #000;
}
[id*='-donation-overlay'] small {
  font-size: 80%;
}
[id*='-donation-overlay'] sub,
[id*='-donation-overlay'] sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
[id*='-donation-overlay'] sup {
  top: -0.5em;
}
[id*='-donation-overlay'] sub {
  bottom: -0.25em;
}
[id*='-donation-overlay'] img {
  border: 0;
}
[id*='-donation-overlay'] svg:not(:root) {
  overflow: hidden;
}
[id*='-donation-overlay'] figure {
  margin: 1em 40px;
}
[id*='-donation-overlay'] hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
[id*='-donation-overlay'] pre {
  overflow: auto;
}
[id*='-donation-overlay'] code,
[id*='-donation-overlay'] kbd,
[id*='-donation-overlay'] pre,
[id*='-donation-overlay'] samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
[id*='-donation-overlay'] button,
[id*='-donation-overlay'] input,
[id*='-donation-overlay'] optgroup,
[id*='-donation-overlay'] select,
[id*='-donation-overlay'] textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
[id*='-donation-overlay'] button {
  overflow: visible;
}
[id*='-donation-overlay'] button,
[id*='-donation-overlay'] select {
  text-transform: none;
}
[id*='-donation-overlay'] button,
[id*='-donation-overlay'] html input[type="button"],
[id*='-donation-overlay'] input[type="reset"],
[id*='-donation-overlay'] input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
[id*='-donation-overlay'] button[disabled],
[id*='-donation-overlay'] html input[disabled] {
  cursor: default;
}
[id*='-donation-overlay'] button::-moz-focus-inner,
[id*='-donation-overlay'] input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
[id*='-donation-overlay'] input {
  line-height: normal;
}
[id*='-donation-overlay'] input[type="checkbox"],
[id*='-donation-overlay'] input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[id*='-donation-overlay'] input[type="number"]::-webkit-inner-spin-button,
[id*='-donation-overlay'] input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[id*='-donation-overlay'] input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
[id*='-donation-overlay'] input[type="search"]::-webkit-search-cancel-button,
[id*='-donation-overlay'] input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
[id*='-donation-overlay'] fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
[id*='-donation-overlay'] legend {
  border: 0;
  padding: 0;
}
[id*='-donation-overlay'] textarea {
  overflow: auto;
}
[id*='-donation-overlay'] optgroup {
  font-weight: bold;
}
[id*='-donation-overlay'] table {
  border-collapse: collapse;
  border-spacing: 0;
}
[id*='-donation-overlay'] td,
[id*='-donation-overlay'] th {
  padding: 0;
}
[id*='-donation-overlay'].fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
[id*='-donation-overlay'].fade.in {
  opacity: 1;
}
[id*='-donation-overlay'] hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}
[id*='-donation-overlay'] code,
[id*='-donation-overlay'] kbd,
[id*='-donation-overlay'] pre,
[id*='-donation-overlay'] samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
[id*='-donation-overlay'] code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  white-space: nowrap;
  border-radius: 4px;
}
[id*='-donation-overlay'] kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #ffffff;
  background-color: #333333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
[id*='-donation-overlay'] pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.428571429;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 4px;
}
[id*='-donation-overlay'] pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
[id*='-donation-overlay'] .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
[id*='-donation-overlay'] label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}
[id*='-donation-overlay'] input[type="radio"],
[id*='-donation-overlay'] input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  /* IE8-9 */
  line-height: normal;
}
[id*='-donation-overlay'] input[type="file"]:focus,
[id*='-donation-overlay'] input[type="radio"]:focus,
[id*='-donation-overlay'] input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
[id*='-donation-overlay'] .radio,
[id*='-donation-overlay'] .checkbox {
  display: block;
  min-height: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
}
[id*='-donation-overlay'] .radio label,
[id*='-donation-overlay'] .checkbox label {
  display: inline;
  font-weight: normal;
  cursor: pointer;
}
[id*='-donation-overlay'] .radio input[type="radio"],
[id*='-donation-overlay'] .radio-inline input[type="radio"],
[id*='-donation-overlay'] .checkbox input[type="checkbox"],
[id*='-donation-overlay'] .checkbox-inline input[type="checkbox"] {
  float: left;
  margin-left: -20px;
}
[id*='-donation-overlay'] .radio + .radio,
[id*='-donation-overlay'] .checkbox + .checkbox {
  margin-top: -5px;
}
[id*='-donation-overlay'] input[type="radio"][disabled],
[id*='-donation-overlay'] input[type="checkbox"][disabled],
[id*='-donation-overlay'] .radio[disabled],
[id*='-donation-overlay'] .radio-inline[disabled],
[id*='-donation-overlay'] .checkbox[disabled],
[id*='-donation-overlay'] .checkbox-inline[disabled],
fieldset[disabled] [id*='-donation-overlay'] input[type="radio"],
fieldset[disabled] [id*='-donation-overlay'] input[type="checkbox"],
fieldset[disabled] [id*='-donation-overlay'] .radio,
fieldset[disabled] [id*='-donation-overlay'] .radio-inline,
fieldset[disabled] [id*='-donation-overlay'] .checkbox,
fieldset[disabled] [id*='-donation-overlay'] .checkbox-inline {
  cursor: not-allowed;
}
[id*='-donation-overlay'] .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none !important;
}
[id*='-donation-overlay'] .btn.btn-primary {
  color: #ffffff;
  background-color: #428bca;
  border-color: #357ebd;
}
[id*='-donation-overlay'] .btn.btn-primary:hover,
[id*='-donation-overlay'] .btn.btn-primary:focus,
[id*='-donation-overlay'] .btn.btn-primary:active,
[id*='-donation-overlay'] .btn.btn-primary.active,
.open .dropdown-toggle[id*='-donation-overlay'] .btn.btn-primary {
  color: #ffffff;
  background-color: #3276b1;
  border-color: #285e8e;
}
[id*='-donation-overlay'] .btn.btn-primary:active,
[id*='-donation-overlay'] .btn.btn-primary.active,
.open .dropdown-toggle[id*='-donation-overlay'] .btn.btn-primary {
  background-image: none;
}
[id*='-donation-overlay'] .btn.btn-primary.disabled,
[id*='-donation-overlay'] .btn.btn-primary[disabled],
fieldset[disabled] [id*='-donation-overlay'] .btn.btn-primary,
[id*='-donation-overlay'] .btn.btn-primary.disabled:hover,
[id*='-donation-overlay'] .btn.btn-primary[disabled]:hover,
fieldset[disabled] [id*='-donation-overlay'] .btn.btn-primary:hover,
[id*='-donation-overlay'] .btn.btn-primary.disabled:focus,
[id*='-donation-overlay'] .btn.btn-primary[disabled]:focus,
fieldset[disabled] [id*='-donation-overlay'] .btn.btn-primary:focus,
[id*='-donation-overlay'] .btn.btn-primary.disabled:active,
[id*='-donation-overlay'] .btn.btn-primary[disabled]:active,
fieldset[disabled] [id*='-donation-overlay'] .btn.btn-primary:active,
[id*='-donation-overlay'] .btn.btn-primary.disabled.active,
[id*='-donation-overlay'] .btn.btn-primary[disabled].active,
fieldset[disabled] [id*='-donation-overlay'] .btn.btn-primary.active {
  background-color: #428bca;
  border-color: #357ebd;
}
[id*='-donation-overlay'] .btn.btn-primary .badge {
  color: #428bca;
  background-color: #ffffff;
}
[id*='-donation-overlay'] .well {
  min-height: 20px;
  padding: 8px 16px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
[id*='-donation-overlay'] .close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
  border: none;
  background: none;
  outline: none;
}
[id*='-donation-overlay'] .close:hover,
[id*='-donation-overlay'] .close:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
[id*='-donation-overlay'] .closebutton {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
[id*='-donation-overlay'].modal-open {
  overflow: hidden;
}
[id*='-donation-overlay'].modal {
  display: none;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
[id*='-donation-overlay'].modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
[id*='-donation-overlay'].modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
[id*='-donation-overlay'] .modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  z-index: 1000;
}
[id*='-donation-overlay'] .modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: none;
}
[id*='-donation-overlay'] .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  background-color: #000000;
}
[id*='-donation-overlay'] .modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
[id*='-donation-overlay'] .modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
[id*='-donation-overlay'] .modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.428571429px;
}
[id*='-donation-overlay'] .modal-header .close {
  margin-top: -2px;
}
[id*='-donation-overlay'] .modal-title {
  margin: 0;
  line-height: 1.428571429;
}
[id*='-donation-overlay'] .modal-body {
  position: relative;
  padding: 20px;
}
[id*='-donation-overlay'] .modal-footer {
  margin-top: 15px;
  padding: 19px 20px 20px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
[id*='-donation-overlay'] .modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
[id*='-donation-overlay'] .modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
[id*='-donation-overlay'] .modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
@media (min-width: 768px) {
  [id*='-donation-overlay'] .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  [id*='-donation-overlay'] .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  [id*='-donation-overlay'].modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  [id*='-donation-overlay'].modal-lg {
    width: 900px;
  }
}
@-ms-viewport {
  width: device-width;
}
[id*='-donation-overlay'] .visible-xs,
[id*='-donation-overlay'] .visible-sm,
[id*='-donation-overlay'] .visible-md,
[id*='-donation-overlay'] .visible-lg {
  display: none !important;
}
@media (max-width: 767px) {
  [id*='-donation-overlay'] .visible-xs {
    display: block !important;
  }
  table[id*='-donation-overlay'] .visible-xs {
    display: table;
  }
  tr[id*='-donation-overlay'] .visible-xs {
    display: table-row !important;
  }
  th[id*='-donation-overlay'] .visible-xs,
  td[id*='-donation-overlay'] .visible-xs {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  [id*='-donation-overlay'] .visible-sm {
    display: block !important;
  }
  table[id*='-donation-overlay'] .visible-sm {
    display: table;
  }
  tr[id*='-donation-overlay'] .visible-sm {
    display: table-row !important;
  }
  th[id*='-donation-overlay'] .visible-sm,
  td[id*='-donation-overlay'] .visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  [id*='-donation-overlay'] .visible-md {
    display: block !important;
  }
  table[id*='-donation-overlay'] .visible-md {
    display: table;
  }
  tr[id*='-donation-overlay'] .visible-md {
    display: table-row !important;
  }
  th[id*='-donation-overlay'] .visible-md,
  td[id*='-donation-overlay'] .visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  [id*='-donation-overlay'] .visible-lg {
    display: block !important;
  }
  table[id*='-donation-overlay'] .visible-lg {
    display: table;
  }
  tr[id*='-donation-overlay'] .visible-lg {
    display: table-row !important;
  }
  th[id*='-donation-overlay'] .visible-lg,
  td[id*='-donation-overlay'] .visible-lg {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  [id*='-donation-overlay'] .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  [id*='-donation-overlay'] .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  [id*='-donation-overlay'] .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  [id*='-donation-overlay'] .hidden-lg {
    display: none !important;
  }
}
[id*='-donation-overlay'] .visible-print {
  display: none !important;
}
@media print {
  [id*='-donation-overlay'] .visible-print {
    display: block !important;
  }
  table[id*='-donation-overlay'] .visible-print {
    display: table;
  }
  tr[id*='-donation-overlay'] .visible-print {
    display: table-row !important;
  }
  th[id*='-donation-overlay'] .visible-print,
  td[id*='-donation-overlay'] .visible-print {
    display: table-cell !important;
  }
}
@media print {
  [id*='-donation-overlay'] .hidden-print {
    display: none !important;
  }
}
.modal-backdrop {
  background: #000;
}
[id*='-donation-overlay'] button:focus,
[id*='-donation-overlay'] a[data-toggle="modal"] {
  outline: none !important;
}
[id*='-donation-overlay'] {
  text-align: left;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
}
[id*='-donation-overlay'] .modal-body {
  padding-bottom: 0;
}
[id*='-donation-overlay'] code {
  background-color: #F7F7F9;
  border: 1px solid #E1E1E8;
}
[id*='-donation-overlay'] label:hover {
  cursor: pointer;
}
[id*='-donation-overlay'] .powered-by {
  color: #ddd;
  float: left;
  font-size: 11px;
  position: relative;
  top: 10px;
}
[id*='-donation-overlay'] .powered-by a {
  color: inherit;
  text-decoration: underline;
}
[id*='-donation-overlay'] .powered-by a:hover {
  color: #428BCA;
}
[id*='-donation-overlay'] a {
  color: #428bca;
  text-decoration: none;
}
[id*='-donation-overlay'] a:hover,
[id*='-donation-overlay'] a:focus {
  color: #2a6496;
  text-decoration: underline;
}
[id*='-donation-overlay'] a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
[id*='-donation-overlay'] input,
[id*='-donation-overlay'] button,
[id*='-donation-overlay'] select,
[id*='-donation-overlay'] textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
[id*='-donation-overlay'] .well {
  background-color: #D9EDF7;
  border-color: #BCE8F1;
  color: #3A87AD;
}
[id*='-donation-overlay'] .well input[type=text] {
  box-shadow: 0 1px 0px #eeeeee inset;
  border-radius: 2px;
  padding: 9px 9px;
  border: 1px solid #ccc;
  min-width: 70px;
}
[id*='-donation-overlay'] table.donation-options {
  width: 100%;
}
[id*='-donation-overlay'] table.donation-options td {
  padding: 5px;
  font-size: 13px;
}
[id*='-donation-overlay'] table.donation-options td input[type=radio] {
  position: relative;
  top: -3px;
}
@media (max-width: 767px) {
  [id*='-donation-overlay'] table.donation-options td {
    text-align: center;
  }
  [id*='-donation-overlay'] table.donation-options td:first-child {
    display: none;
  }
  [id*='-donation-overlay'] table.donation-options td label {
    width: 100%;
    padding: 0;
    text-align: center;
    display: block;
  }
  [id*='-donation-overlay'] table.donation-options td label small {
    font-size: inherit;
  }
  [id*='-donation-overlay'] table.donation-options td input[type=radio] {
    display: none;
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
  }
}
[id*='-donation-overlay'] table.donation-payment {
  width: auto;
  margin: 0 auto;
}
[id*='-donation-overlay'] table.donation-payment tr {
  height: 195px;
}
[id*='-donation-overlay'] table.donation-payment td:last-child {
  width: 99%;
}
[id*='-donation-overlay'] table {
  border: 0;
}
[id*='-donation-overlay'] table td {
  border: 0;
}
@media (max-width: 767px) {
  [id*='-donation-overlay'] table.donation-payment td {
    width: 100%;
    text-align: center;
    float: left;
  }
}
[id*='-donation-overlay'] table.donation-payment .well {
  background: #000;
}
[id*='-donation-overlay'] #qrcode {
  display: block;
  height: 190px;
  width: 190px;
  position: relative;
  text-align: center;
  margin: 0 auto;
}
@media (min-width: 768px) {
  [id*='-donation-overlay'] #qrcode {
    padding: 10px 20px 10px 0;
  }
}
[id*='-donation-overlay'] #qrcode:active,
[id*='-donation-overlay'] #qrcode:focus,
[id*='-donation-overlay'] #qrcode:hover {
  text-decoration: none;
}
[id*='-donation-overlay'] .qrcode-placeholder {
  color: #333;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  font-weight: bold;
}
